import React, { useRef } from 'react';
import { Text, Flex, Box } from '@chakra-ui/react';
import Container from '../components/Container';
import { headers } from '../headers';
import Header from '../components/Header';

function Services() {
  const seancesIndividuellesRef = useRef();
  const baladesCollectivesPourChiotsRef = useRef();
  const baladesCollectivesPourChiensAdultesRef = useRef();
  const seancesCollectivesRef = useRef();
  const anchors = [
    {
      title: 'séances individuelles',
      ref: seancesIndividuellesRef,
      index: 1,
    },
    {
      title: 'séances collectives',
      ref: seancesCollectivesRef,
      index: 2,
    },
    {
      title: 'balades collectives pour chiots',
      ref: baladesCollectivesPourChiotsRef,
      index: 3,
    },
    {
      title: 'balades collectives pour chiens adultes',
      ref: baladesCollectivesPourChiensAdultesRef,
      index: 4,
    },
  ];

  const pavingStones = [
    {
      content:
        "Lors de la première séance, je me déplace à votre domicile afin d'échanger avec vous sur vos attentes vis-à-vis de votre chien. J'observe son comportement afin d'en tirer un premier bilan comportemental à l'écrit.\n\nLes séances suivantes permettent quant à elles de mettre en application des exercices adaptés au binôme maître/chien. Ces derniers peuvent aussi bien se faire chez vous, en ville, en forêt ou tout autre endroit adapté au travail à fournir.\n\nIl est nécessaire de poursuivre de votre côté les exercices abordés lors des séances afin de faciliter la progression du binôme. Il est capital pour moi de vous aider à interpréter les actions de votre chien et à communiquer sereinement avec ce dernier.\n\nMa chienne pourra intervenir au besoin durant certaines séances en fonction des problèmes rencontrés afin de présenter ou renforcer les bons codes canins à avoir en extérieur en présence d’autres chiens (réactivité ou crainte des congénères, suivi naturel, rappel, gestion des émotions, etc)\n\nUne fiche de suivi est mise en place, elle liste les exercices et comportements à appliquer et est enrichie d'un résumé de chaque séance. Cet outil offre de la visibilité sur les progrès réalisés par le binôme tout au long de l'accompagnement.\n\nChaque séance dure une heure afin d'éviter une surcharge mentale trop importante chez le chien. Après 30 à 45 minutes de travail, Le temps restant est consacré à l'aspect théorique.",
      ref: seancesIndividuellesRef,
    },
    {
      content:
        'Une séance collective dure environ une heure et se déroule généralement sur de larges sentiers de forêt. On débute les exercices avec la sortie du véhicule. Ces derniers sont adaptés à chaque chien, parfois effectués en marchant, rendant chaque séance unique.\n\nIl est tout a fait envisageable de lâcher les chiens (ou de les mettre en longe) afin de faire redescendre l’excitation. En effet, il est difficile pour un chien de rester concentré pendant une heure sans effectuer de pause. Il peut s’agir de travailler le suivi naturel, le rappel, l’écoute avec les ordres de base, l’excitation dans un contexte spécifique, la gestion de la frustration, l’apprentissage de la patience, etc',
      ref: seancesCollectivesRef,
    },
    {
      content:
        "Pour le bien-être mental et physique du chiot, il est indispensable d’introduire dès l’âge de 2 mois des balades entre chiots avec la présence de chiens adultes. Celles-ci permettent l’apprentissage des codes canins, le respect mutuel, le goût au jeu ainsi que la gestion de la frustration et de l’excitation.\n\nCes balades se déroulent avec un maximum de 3 à 4 chiots en fonction des ententes et des capacités de ma chienne. Leur durée varie entre 45 minutes et une heure en fonction de la capacité physique des chiots. Pour les plus jeunes chiots, il est possible d'effectuer 30 minutes de balades suivies de 30 minutes de partage de mes compétence dans l’éducation.\n\nMa chienne est présente durant la balade afin de transmettre les bon comportements au chiot par mimétisme.\n\nDurant les séances, il sera proposé de travailler le suivi naturel, le rappel, la sociabilisation avec les humains et les congénères ainsi que l’exploration d'endroits divers afin de varier les bruits et les odeurs.",
      ref: baladesCollectivesPourChiotsRef,
    },
    {
      content:
        "Ces séances permettent de conserver une bonne sociabilisation entre les chiens - point à travailler tout au long de la vie de l'animal pour son équilibre - à travers le jeu et les interactions.\n\nLes balades en groupe favorisent également la dépense mentale et physique du chien. Une balade type commence par quelques minutes de marche en laisse pour faire descendre l'excitation en sortie de voiture, puis le lâchage des chiens (ou passage en longe) pour les laisser interagir entre à leur bon vouloir.\n\nEn fonction des besoins des chiens, il est possible de faire des petits exercices tout au long de la balade comme le rappel, le \"pas bouger\", la remise en laisse, l’éloignement du groupe pour travailler la frustration, etc Il est important d’avoir un chien à l’écoute de son maître en toute circonstances, d'où l'intérêt de ce type de séance.\n\nLes balades se font toujours en comité réduit (maximum de 4 chiens) et ma chienne pourra être présente en fonction du caractère propre à chaque chien, le tout sur des durées de 1 à 2 heures en fonction de votre temps disponible, du lieu et de la capacité physique de votre chien.",
      ref: baladesCollectivesPourChiensAdultesRef,
    },
  ];

  const Title = ({ anchor, redirect }) => (
    <Flex
      flexDir="row"
      gap={2}
      onClick={() => {
        if (redirect) {
          anchor.ref.current?.scrollIntoView();
        }
      }}
      cursor={redirect ? 'pointer' : 'normal'}
    >
      <Box bgColor="#C4DEB5" w={30} h={30} rounded="full" flexShrink="0">
        <Text fontSize="large" fontWeight="bold" textAlign="center">
          {anchor.index}
        </Text>
      </Box>
      <Text
        fontSize="large"
        fontWeight="bold"
        _hover={
          redirect
            ? {
                color: '#C4DEB5',
              }
            : {}
        }
      >
        {`${anchor.title.toUpperCase()}`}
      </Text>
    </Flex>
  );

  const intro =
    'Nos différentes prestations s’adaptent à vos besoins,\n' +
    'choisissez la votre ou contactez-moi pour vous aider dans votre choix';
  return (
    <Container>
      <Flex flexDir="row" alignItems="end" gap={2}>
        <Text>👇</Text>
        <Text whiteSpace="pre-line" fontSize="large" textAlign="center">
          {intro}
        </Text>
        <Text>👇</Text>
      </Flex>
      <Flex mt={8} gap={4} flexDir="row" flexWrap="wrap" maxW={800}>
        <Flex flexDir="column" gap={2}>
          {anchors.slice(0, 2).map(anchor => (
            <Title anchor={anchor} redirect />
          ))}
        </Flex>
        <Flex flexDir="column" gap={2}>
          {anchors.slice(2, 4).map(anchor => (
            <Title anchor={anchor} redirect />
          ))}
        </Flex>
      </Flex>
      <Flex flexDir="column" gap={8} mt={8}>
        {pavingStones.map((pavingStone, index) => (
          <Flex
            bg="white"
            padding={5}
            borderRadius={'xl'}
            ref={pavingStone.ref}
            whiteSpace="pre-line"
            flexDir="column"
            gap={4}
          >
            <Title
              anchor={anchors.find(anchor => anchor.index === index + 1)}
            />
            {pavingStone.content}
          </Flex>
        ))}
      </Flex>
    </Container>
  );
}

export default Services;

export function Head() {
  return <Header {...headers.services} />;
}
